<template>
  <div>
    <AppModal :value="true" :title="$t('PageLoginNewTerms.Title').value">
      <template #header>
        <AppModalHeader>
          <template #right>
            <AppBackButton analytics-name="login-new-terms-back" @click="onDismiss" />
          </template>
        </AppModalHeader>
      </template>

      <AppCardImageTitle
        :alt="$t('PageLoginNewTerms.FigureImage.AltText').value"
        :src="require('@galileo/assets/images/illustrations/document.svg')"
        :title="$t('PageLoginNewTerms.Title').value"
      >
        <TermsConditions class="mt-4" :is-subtitle="true" />
      </AppCardImageTitle>
      <template #footer>
        <AppModalFooter>
          <AppButton :loading="loading" analytics-name="login-new-terms-agree" @click="agreeTerms">
            {{ $t('PageLoginNewTerms.ButtonAgree').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>

    <!-- Dismiss dialog -->
    <AppDialog v-model="isDismissDialogOpen">
      <template #header>
        <AppDialogHeader>{{ $t('PageLoginNewTerms.CancelDialog.Heading').value }} </AppDialogHeader>
      </template>
      {{ $t('PageLoginNewTerms.CancelDialog.Description').value }}
      <template #footer>
        <AppDialogFooter>
          <AppButton
            theme="text"
            analytics-name="login-new-terms-dismiss-dialog-logout"
            @click="onLogout"
            >{{ $t('PageLoginNewTerms.CancelDialog.LogoutButton').value }}
          </AppButton>
          <AppButton analytics-name="login-new-terms-dismiss-dialog-close" @click="onCloseDialog">{{
            $t('PageLoginNewTerms.CancelDialog.CancelButton').value
          }}</AppButton>
        </AppDialogFooter>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

import { useRouter } from '@galileo/composables/useRouter'
import TermsConditions from '@galileo/components/TermsConditions/TermsConditions'
import { useI18nStore, useAuthStore } from '@galileo/stores'

import {
  AppCardImageTitle,
  AppModal,
  AppModalFooter,
  AppModalHeader,
  AppBackButton,
  AppButton,
  AppDialog,
  AppDialogHeader,
  AppDialogFooter,
} from '@oen.web.vue2/ui'

export default {
  name: 'LoginNewTerms',
  components: {
    TermsConditions,
    AppCardImageTitle,
    AppModal,
    AppModalFooter,
    AppButton,
    AppModalHeader,
    AppBackButton,
    AppDialog,
    AppDialogHeader,
    AppDialogFooter,
  },
  setup() {
    const { $t, locale } = useI18n()
    const router = useRouter()

    const authStore = useAuthStore()

    const loading = ref(false)
    const isDismissDialogOpen = ref(false)

    const agreeTerms = async () => {
      loading.value = true
      await authStore.acceptNewTerms()
      authStore.checkLoginRequirements(true)
    }

    const onDismiss = () => {
      isDismissDialogOpen.value = true
    }

    const onLogout = async () => {
      router.push({
        name: 'Login',
      })
    }

    const onCloseDialog = () => {
      isDismissDialogOpen.value = false
    }

    return {
      agreeTerms,
      loading,
      $t,
      locale,
      onDismiss,
      isDismissDialogOpen,
      onLogout,
      onCloseDialog,
    }
  },
}
</script>
