<template>
  <div class="profile-selection-card">
    <AppSpinnerBig :loading="loading"></AppSpinnerBig>
    <LayoutPage analytics-name="profile-selection">
      <template #title>{{ title }}</template>
      <template #content>
        <div class="content-text">{{ content }}</div>
        <div class="profile-selection-container">
          <AppCard>
            <AppList>
              <template>
                <AppListItem
                  v-for="detailedProfile in detailedProfiles"
                  :key="detailedProfile.id"
                  :analytics-name="`profile-item-${detailedProfile.id}`"
                  @itemClick="selectProfile(detailedProfile)"
                >
                  <template #figure>
                    <AppFigure class="xe-figure">
                      <AppIcon name=" ">
                        <IconUser v-if="detailedProfile.accountType === 'Consumer'" />
                        <IconPortfolio v-if="detailedProfile.accountType === 'Corporate'" />
                      </AppIcon>
                    </AppFigure>
                  </template>
                  <template #left>
                    <AppListItemTitle>{{ detailedProfile.name }}</AppListItemTitle>
                    <AppListItemCaption
                      >{{ detailedProfile.region }}
                      {{
                        detailedProfile.accountType === 'Consumer'
                          ? $t('PageProfileSelection.PersonalProfileText').value
                          : $t('PageProfileSelection.BusinessProfileText').value
                      }}
                      | {{ detailedProfile.clientNumber }}</AppListItemCaption
                    >
                  </template>

                  <template #action>
                    <slot name="action">
                      <AppIcon name=" ">
                        <slot name="action-icon">
                          <IconChevronRight />
                        </slot>
                      </AppIcon>
                    </slot>
                  </template>
                </AppListItem>
              </template>
            </AppList>
          </AppCard>
        </div>
      </template>
    </LayoutPage>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import LayoutPage from '@galileo/components/LayoutPage/LayoutPage'
import { useRouter } from '@galileo/composables/useRouter'

import { IconChevronRight, IconUser, IconPortfolio } from '@oen.web.vue2/icons'

import {
  AppListItem,
  AppList,
  AppCard,
  AppListItemTitle,
  AppListItemCaption,
  AppIcon,
  AppSpinnerBig,
  AppFigure,
} from '@oen.web.vue2/ui'

import { storeToRefs } from 'pinia'
import { useI18nStore, useAuthStore } from '@galileo/stores'

export default {
  name: 'ProfileSelection',
  components: {
    LayoutPage,
    AppListItemTitle,
    AppListItem,
    AppList,
    AppCard,
    AppIcon,
    AppSpinnerBig,
    IconChevronRight,
    AppListItemCaption,
    IconUser,
    IconPortfolio,
    AppFigure,
  },
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const title = $t('PageProfileSelection.Title').value
    const content = $t('PageProfileSelection.Content').value
    const authStore = useAuthStore()
    const loading = ref(false)
    const detailedProfiles = computed(() => {
      let profiles = authStore.getUserProfiles()

      return profiles.filter((profile) => profile.status === 'Active')
    })
    const selectProfile = async (profile) => {
      loading.value = true
      authStore.selectedUser = profile.customer
      const { getPostLoginRoute } = storeToRefs(authStore)

      await authStore.selectProfile(profile.id, profile.accountType, router)

      if (profile.accountType === 'Consumer') {
        if (getPostLoginRoute.value) {
          router.push(getPostLoginRoute.value)
        } else {
          router.push({ name: 'Activity' })
        }
      }
      loading.value = false
    }

    return {
      title,
      loading,
      detailedProfiles,
      content,
      selectProfile,
      $t,
    }
  },
}
</script>
<style scoped>
::v-deep .navbar {
  @apply border-b-0;
}
::v-deep .navbar-container {
  justify-content: space-between;
}

::v-deep .navbar-center {
  display: none;
}
::v-deep .navbar-right {
  flex: 0 1 auto;
}

::v-deep .layout-page.layout-page--xs .card .card-header {
  @apply pt-12;
  @screen xs {
    @apply block;
    @apply border-b-0;
    @apply pt-0;
  }
}
.card-header .card-header-title {
  @apply text-2xl font-medium;
}
.content-text {
  @apply text-sm text-center;
  @apply m-auto mb-8;
  max-width: 222px;
}
.profile-selection-container {
  /deep/ .card-content-block {
    @screen sm {
      @apply m-2;
    }
  }
  /deep/ .card-content {
    @screen sm {
      @apply px-3;
    }
  }
  /deep/ .card {
    @apply shadow-lg;
  }
}
.list-item {
  @apply border-none;
}
.figure--gray {
  background-color: rgba(250, 251, 253, 1);
  border: 1px solid rgba(243, 244, 246, 1);
}
.list-item-caption {
  @apply text-xs;
}
::v-deep .card-content {
  @screen sm {
    @apply pt-2;
  }
}
.card--has-content {
  @apply rounded-lg;
}
</style>
